import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import Footer from '../../components/footer';
import Image from 'react-bootstrap/Image';
import CarouselModal from '../../components/modals/carouselModal';
import LazyHero from 'react-lazy-hero';
import 'bootstrap/dist/css/bootstrap.min.css';
import fallning1 from '../../public/photos/fallning/fallning1.jpg';
import fallning2 from '../../public/photos/fallning/fallning2.jpg';
import fallning3 from '../../public/photos/fallning/fallning3.jpg';
import fallning4 from '../../public/photos/fallning/fallning4.jpg';
import fallning5 from '../../public/photos/fallning/fallning5.jpg';
import fallning6 from '../../public/photos/fallning/fallning6.jpg';
import fallning7 from '../../public/photos/fallning/fallning7.jpg';
import main_falning from '../../public/photos/fallning/main/main_falning.jpg';

const items = [
    {source:fallning1, id:1},
    {source:fallning2, id:2},
    {source:fallning3, id:3},
    {source:fallning4, id:4},
    {source:fallning5, id:5},
    {source:fallning6, id:6},
    {source:fallning7, id:7}
]


class Fallning extends Component{
    constructor(props){
        super(props);
        this.state= {
            images: items,
            currentImageNumber: 0,
            showModal: false
        };

        this.carouselHandler.bind(this);
    }

    toggleModal = async(stuff) => {
        await this.setState(({showModal: !this.state.showModal, currentImageNumber: stuff-1}));
    }

    changeImgNumberAndOpenModal = (index) => {
        this.setState(({currentImageNumber: index}));
        this.toggleModal();

    }
    carouselHandler = (dataFromCarousel) =>{
        this.setState({showModal: dataFromCarousel});
    }
    render(){

        var textStyle = { 
          position: 'absolute',
          left: '50%',
          top: '90%'
        };
        return(
            <React.Fragment>
                <LazyHero imageSrc={main_falning}
                opacity={0} 
                parallaxOffset={200}
                minHeight="85vh" 
                isCentered={true} >
                <h1 style={textStyle}> </h1>
                </LazyHero>
                <Container fluid>
                    <div>
                        <br></br>
                        <br></br>
                        <Row>
                            <Col sm="12" md={{ size: 5, offset: 4 }}>
                                <h5>
                                    <p>
                                    Det första intrycket är viktigt och många gånger bestående – inte bara hos oss människor, utan även för våra hus. 
                                    Det första dina gäster och besökare möts av är ofta gångar, körvägar, garageuppfart, uteplatser eller trappor och därför är det extra trevligt om de är välskötta.
                                     Har du inte tid eller kunskap hjälper gärna vi dig att utforma, bättra på eller att från grunden skapa stenläggning anpassad efter din tomt.
                                     Kontakta oss så kan vi titta på ett upplägg för stenläggning som passar just dig.
                                    </p>
                                </h5>
                            </Col>
                        </Row>
                        <div >
                            <br></br>
                            <br></br>
                            <Row>
                                <Col md={{offset: 5}}>
                                    <p style={{fontStyle: 'italic', fontSize: '20px'}}>
                                        Ex. på trädfallning och stensättning
                                    </p>
                                </Col>
                            </Row>
                            <br></br>
                            <Container>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[0].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[0].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[1].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[1].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[2].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[2].id)}/></Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[3].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[3].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[4].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[4].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[5].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[5].id)}/></Col>
                                </Row>
                                <br></br>
                                <Row>
                                <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[6].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[6].id)}/></Col>
                                    <li></li><li></li> 
                                </Row>
                                <br></br>
                            </Container>
                        </div>
                    </div>
                </Container>
                <CarouselModal visible={this.state.showModal} images={this.state.images} imageId={this.state.currentImageNumber} closeCarousel={this.carouselHandler}/>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default Fallning;