import {BACKEND_URL, TIMESHEET_ALL, TIMESHEET_ISSTARTED, TIMESHEET_USER_LAST, TIMESHEET_STOP, TIMESHEET_GETONE} from '../helpers/urls';

export const TimesheetService = {
    getUserTimesheets,
    isTimerStarted,
    getLastTimesheet,
    stopTimesheet,
    getTimesheetById
};

async function getUserTimesheets(userId){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json; charset=UTF-8'}
    };

    var response = await fetch(BACKEND_URL + TIMESHEET_ALL + userId);
    var data = await response.json();

    console.log(data);
    return data;
}

async function isTimerStarted(userId){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json; charset=UTF-8'}
    };

    var response = await fetch(BACKEND_URL + TIMESHEET_ISSTARTED + userId);
    var data = await response.json();

    return data;
}

async function getLastTimesheet(userId){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json; charset=UTF-8'}
    };

    var response = await fetch(BACKEND_URL + TIMESHEET_USER_LAST + userId);
    var data = await response.json();

    console.log(data);
    return data;
}

async function stopTimesheet(timesheetId, endTime){
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json; charset=UTF-8'},
        body: JSON.stringify({
            id: timesheetId,
            end_time: endTime
        })
    };

    var response = await fetch(BACKEND_URL + TIMESHEET_STOP, requestOptions);

    console.log(response);
    return response;
}

async function getTimesheetById(timesheetId){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json; charset=UTF-8'}
    };

    var response = await fetch(BACKEND_URL + TIMESHEET_GETONE + timesheetId);
    var data = await response.json();

    console.log(data);
    return data;
}

export default TimesheetService;
