import React, {Component} from 'react';

class Contact extends Component{
    constructor(props){
        super(props);
        this.state= {

        };
    }
    render(){
        return(
            <React.Fragment>
                Contact page
            </React.Fragment>
        )
    }
}

export default Contact;