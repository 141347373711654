import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import Footer from '../../components/footer';
import Image from 'react-bootstrap/Image';
import CarouselModal from '../../components/modals/carouselModal';
import LazyHero from 'react-lazy-hero';
import 'bootstrap/dist/css/bootstrap.min.css';
import isolering1 from '../../public/photos/isolering/isolering1.jpg';
import isolering2 from '../../public/photos/isolering/isolering2.jpg';
import isolering3 from '../../public/photos/isolering/isolering3.jpg';
import isolering4 from '../../public/photos/isolering/isolering4.jpg';
import isolering5 from '../../public/photos/isolering/isolering5.jpg';
import isolering6 from '../../public/photos/isolering/isolering6.jpg';
import main_isolering from '../../public/photos/isolering/main/main_isolering.jpg';

const items = [
    {source:isolering1, id:1},
    {source:isolering2, id:2},
    {source:isolering3, id:3},
    {source:isolering4, id:4},
    {source:isolering5, id:5},
    {source:isolering6, id:6}
]


class Isolering extends Component{
    constructor(props){
        super(props);
        this.state= {
            images: items,
            currentImageNumber: 0,
            showModal: false
        };

        this.carouselHandler.bind(this);
    }

    toggleModal = async(stuff) => {
        await this.setState(({showModal: !this.state.showModal, currentImageNumber: stuff-1}));
    }

    changeImgNumberAndOpenModal = (index) => {
        this.setState(({currentImageNumber: index}));
        this.toggleModal();

    }
    carouselHandler = (dataFromCarousel) =>{
        this.setState({showModal: dataFromCarousel});
    }
    render(){

        var textStyle = { 
          position: 'absolute',
          left: '30%',
          top: '85%',
          color: 'white'
        };
        return(
            <React.Fragment>
                <LazyHero imageSrc={main_isolering}
                opacity={0} 
                parallaxOffset={200}
                minHeight="85vh" 
                isCentered={true} >
                <h2 style={textStyle}>Är det kallt på vintern? Vi har lösningen för dig!</h2>
                </LazyHero>
                <Container fluid>
                    <div>
                        <br></br>
                        <br></br>
                        <Row>
                            <Col sm="12" md={{ size: 5, offset: 4 }}>
                                <h5>
                                    <p>
                                        Som kund kan du få vår hjälp med att isolera vindar, snedtak, väggar och golv på ett effektivt sätt. 
                                        När du vänder dig till oss kommer du få all den hjälpen du behöver med isoleringen. 
                                    </p>
                                    <p>
                                        Att isolera med cellulosaisolering är effektivt, miljövänligt och rationellt. 
                                        Installationen sker direkt in i konstruktionen på ett fuktsäkert sätt. Om du vänder dig till oss får du en isolering 
                                        som har en hög densitet som dessutom behåller värmen. Med den här typen av isolering får du ett hus som andas, 
                                        vilket gör att både du och ditt hus mår bra. 
                                    </p>
                                </h5>
                            </Col>
                        </Row>
                        <div >
                            <br></br>
                            <br></br>
                            <Row>
                                <Col md={{offset: 5}}>
                                    <p style={{fontStyle: 'italic', fontSize: '20px'}}>
                                        Ex. på isoleringsjobb
                                    </p>
                                </Col>
                            </Row>
                            <br></br>
                            <Container>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[0].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[0].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[1].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[1].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[2].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[2].id)}/></Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[3].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[3].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[4].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[4].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[5].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[5].id)}/></Col>
                                </Row>
                                <br></br>
                            </Container>
                        </div>
                    </div>
                </Container>
                <CarouselModal visible={this.state.showModal} images={this.state.images} imageId={this.state.currentImageNumber} closeCarousel={this.carouselHandler}/>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default Isolering;