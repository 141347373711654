import {BACKEND_URL, LOGIN} from '../helpers/urls';

export const userService = {
    login,
    logout
};

//loginType, name, password
async function login(loginType, name, password){
    const data = {
        "type" : loginType,
        "username": name,
        "password": password 
    };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        body: JSON.stringify(data)
    };

    return await fetch(BACKEND_URL + LOGIN, requestOptions)
        .then(handleResponse)
        .then(data => { 
            if(data){
                var user = data;
                localStorage.setItem('user', JSON.stringify(user));
                window.location.href = "http://localhost:3000/timesheet";
                return user;
            }
        })        
}

function logout(){
    localStorage.clear();
    window.location.reload(true);
}

function handleResponse(response){
    console.log(response)
    console.log("status:")
    console.log(response.status)
    if(response.status == 200){
        return response.json();
    }
    else{return null}
    
    //return response;
}

export default userService;
