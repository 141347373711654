
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import isLoggedIn from '../../utils';
import Home from '../../pages/public/home';

const PrivateRoute = ({component: Component, ...rest}) => {
  return (

      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page
      <Route {...rest} render={props => (
          isLoggedIn ?
              <Component {...props} />
          : <Redirect to="/home" />
      )} />
  );
};

export default PrivateRoute;