import {BACKEND_URL, CUSTOMER_ALL, CUSTOMER_NAMES} from '../helpers/urls';

export const CustomerService = {
    getAllCustomers,
    getCustomerNames
}

async function getAllCustomers(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json; charset=UTF-8'}
    };

    var response = await fetch(BACKEND_URL + CUSTOMER_ALL);
    var data = await response.json();

    console.log(data);
    return data;
}

async function getCustomerNames(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json; charset=UTF-8'}
    };

    var response = await fetch(BACKEND_URL + CUSTOMER_NAMES);
    var data = await response.json();

    console.log(data);
    return data;
}

export default CustomerService;
