import React, {Component} from 'react';
import {Container} from 'reactstrap';
import Table from 'react-bootstrap/Table';
import Timesheet from '../private/timesheet';
import Slideshow from '../../components/slideShow';
import Footer from '../../components/footer';

class Home extends Component{
    constructor(props){
      super(props);
    }
  
    render(){
      return(
        <React.Fragment>
          <div>
            <Slideshow/>
          </div>
          <div
          style={{backgroundColor: '#212529'}}
          >
            <Container>
            <h5 style={{whiteSpace:'pre-wrap', color:'#bec6cc'}}>
              AS Bygg El & Alltjänst AB
              <br></br>
              J A Bobäcks gata 4
              <br></br>
              544 31 HJO
              <br></br>
              073-976 29 14
              <br></br>
              asbyggalltjanst@gmail.com
              <br></br>
            </h5>
            </Container>
          </div>
        </React.Fragment>
      )
    }
  }
  
  export default Home;
  