export const BACKEND_URL = 'http://127.0.0.1:8080/api/';

export const LOGIN = 'users/login';

export const TIMESHEET = 'timesheets/'

export const TIMESHEET_CREATE = TIMESHEET + 'create/';

export const TIMESHEET_GETONE = TIMESHEET + 'get/';

export const TIMESHEET_UPDATE = TIMESHEET + 'update/';

export const TIMESHEET_STOP = TIMESHEET + 'stop/';

export const TIMESHEET_DELETE = TIMESHEET + 'delete/';

export const TIMESHEET_ISSTARTED = TIMESHEET + 'user/isstarted/';

export const TIMESHEET_USER_LAST = TIMESHEET + 'last/';

export const TIMESHEET_ALL = TIMESHEET + 'all/user/';

export const CUSTOMER = 'customers/';

export const CUSTOMER_ALL = CUSTOMER + 'get/all/';

export const CUSTOMER_DELETE = CUSTOMER + 'delete/';

export const CUSTOMER_ADD = CUSTOMER + 'create/';

export const CUSTOMER_NAMES = CUSTOMER + 'get/names/';

export const PROJECT = 'projects/';

export const PROJECTS_ALL_NAMES = 'get/names';

export const PROJECTS_FOR_CUSTOMER = PROJECT + 'get/all/';

