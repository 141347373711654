import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal'
import { ModalHeader, ModalBody, ModalFooter, ButtonGroup, Button } from 'reactstrap';
import {Form} from 'react-bootstrap';
import userService from '../../services/userService';

class LoginModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            showModal: this.props.visible,
            username: "",
            loginType: "username",
            password: "",
            showError: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.showDialogModal != nextProps.visible) {
          this.setState({
            showModal: nextProps.visible
           });
          }
        }

        closeModal = () => {
            this.setState({showModal: false})
            this.props.closeModal(false)
        }

        login = async() => {
            if(this.state.username.includes("@")){
                 await this.setState({loginType: "email"})
            };
            var loginData = {
                loginType: this.state.loginType,
                user: this.state.username,
                password: this.state.password
            }

            var response = await userService.login(loginData.loginType, loginData.user, loginData.password);
            console.log("response: ");
            if(!response){
                console.log("show bad info")
                console.log(response)
                this.showError();
            }
            else {
                console.log("success")
            }
        }

        showError = async () => {
            await this.setState({showError: true})
        }
        

    render(){
        return(
            <React.Fragment>
                <Modal show={this.state.showModal} onHide={this.closeModal.bind(this)}>
                    <Modal.Header>Logga in</Modal.Header>
                    <ModalBody>
                        <Form>
                            <Form.Group controlId="userName">
                                <Form.Label column sm="2">
                                    Användarnamn/email
                                </Form.Label>
                                <Form.Control type="username" onChange={e => this.setState({username: e.target.value})}/>
                            </Form.Group>
                            <Form.Group controlId="password">
                                <Form.Label column sm="2">
                                    Lösenord
                                </Form.Label>
                                <Form.Control type="password" onChange={e=> this.setState({password: e.target.value})}/>
                            </Form.Group>
                            { this.state.showError &&
                            <Form.Group>
                                <Form.Label>
                                    Användarnamn / email eller lösenord var felaktig
                                </Form.Label>
                            </Form.Group>
                            }
                            <Form.Group>
                                <Button onClick={() => this.login()}>Log in</Button>
                                <Button onClick={this.closeModal.bind(this)}>Cancel</Button>
                            </Form.Group>
                        </Form>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}
export default LoginModal;