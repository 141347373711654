import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import Footer from '../../components/footer';
import Image from 'react-bootstrap/Image';
import CarouselModal from '../../components/modals/carouselModal';
import LazyHero from 'react-lazy-hero';
import 'bootstrap/dist/css/bootstrap.min.css';
import badrum1 from '../../public/photos/badrum/badrum1.jpg';
import badrum2 from '../../public/photos/badrum/badrum2.jpg';
import badrum3 from '../../public/photos/badrum/badrum3.jpg';
import badrum4 from '../../public/photos/badrum/badrum4.jpg';
import badrum5 from '../../public/photos/badrum/badrum5.jpg';
import badrum6 from '../../public/photos/badrum/badrum6.jpg';
import badrum7 from '../../public/photos/badrum/badrum7.jpg';
import badrum8 from '../../public/photos/badrum/badrum8.jpg';
import main_badrum from '../../public/photos/badrum/main/main_badrum.jpg';

const items = [
    {source:badrum1, id: 1},
    {source:badrum2, id: 2},
    {source:badrum3, id: 3},
    {source:badrum4, id: 4},
    {source:badrum5, id: 5},
    {source:badrum6, id: 6},
    {source:badrum7, id: 7},
    {source:badrum8, id: 8},
]


class Badrum extends Component{
    constructor(props){
        super(props);
        this.state= {
            images: items,
            currentImageNumber: 0,
            showModal: false
        };

        this.carouselHandler.bind(this);
    }

    toggleModal = async(stuff) => {
        await this.setState(({showModal: !this.state.showModal, currentImageNumber: stuff-1}));
    }

    carouselHandler = (dataFromCarousel) =>{
        this.setState({showModal: dataFromCarousel, imageNumber: this.state.currentImageNumber});
    }
    render(){

        var textStyle = { 
          position: 'absolute',
          left: '50%',
          top: '90%'
        };
        return(
            <React.Fragment>
                <LazyHero imageSrc={main_badrum}
                opacity={0} 
                parallaxOffset={200}
                minHeight="85vh" 
                isCentered={true} >
                <h1 style={textStyle}> </h1>
                </LazyHero>
            

                
                <Container fluid>
                    <div>
                        <br></br>
                        <br></br>
                        <Row>
                            <Col sm="12" md={{ size: 5, offset: 4 }}>
                                <h5>
                                    <p>
                                        Nyckeln till en lyckad badrumsrenovering är ordentlig planering och ett kunnigt utförande som borgar för både snygga, funktionella och säkra slutresultat.
                                        Det är oerhört viktigt att man ägnar åtanke till varje liten detalj för att inte missa något väsentligt eller fatta ett beslut som man kanske ångrar i framtiden.
                                    </p>
                                    <p>
                                        Börja med att lista vilka behov ditt nya badrum behöver fylla och vilka funktioner som är nödvändiga. 
                                        I större hushåll kan det till exempel underlätta med dubbla handfat och extra förvaringsutrymmen medan andra prioriterar 
                                        komfort i form av ett massagebadkar eller en mindre spaavdelning.
                                    </p>
                                    <p>
                                        Även stilvalet bör behandlas redan tidigt i planeringen då den i regel styr valet av möbler, belysning, toalettstol och beslag. 
                                        Leta inspiration från bilder, vänner samt butiker och försök hitta en som passar din personlighet samtidigt som den känns 
                                        tidlös och inget du tröttnar på. Här sätter enbart fantasin gränser och vi kan hjälpa dig skapa allt från mönsterrika, 
                                        unika rum till klassiskt helvitt med klinkergolv i grått och infällda spotlights i taket. Behöver du råd och tips delar 
                                        vi gärna med oss av vår kunskap för att du ska få ut maximalt av ditt nya badrum. 
                                    </p> 
                                </h5>    
                            </Col>
                        </Row>
                        <div >
                            <br></br>
                            <br></br>
                            <Row>
                                <Col md={{offset: 5}}>
                                    <p style={{fontStyle: 'italic', fontSize: '20px'}}>
                                        Ex. på badrumsrenoveringar
                                    </p>
                                </Col>
                            </Row>
                            <br></br>
                            <Container>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[0].source} alt={this.state.images[0].id} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[0].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[1].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[1].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[2].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[2].id)}/></Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[3].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[3].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[4].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[4].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[5].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[5].id)}/></Col>
                                    <li></li><li></li>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[6].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[6].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[7].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[7].id)}/></Col>
                                    <li></li><li></li>
                                </Row>
                                <br></br>
                            </Container>
                        </div>
                    </div>
                </Container>
                <CarouselModal visible={this.state.showModal} images={this.state.images} imageId={this.state.currentImageNumber} closeCarousel={this.carouselHandler}/>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default Badrum;