import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import Footer from '../../components/footer';
import Image from 'react-bootstrap/Image';
import CarouselModal from '../../components/modals/carouselModal';
import LazyHero from 'react-lazy-hero';
import 'bootstrap/dist/css/bootstrap.min.css';
import renovering1 from '../../public/photos/renovering/renovering1.jpg';
import renovering2 from '../../public/photos/renovering/renovering2.jpg';
import renovering3 from '../../public/photos/renovering/renovering3.jpg';
import renovering4 from '../../public/photos/renovering/renovering4.jpg';
import renovering5 from '../../public/photos/renovering/renovering5.jpg';
import renovering6 from '../../public/photos/renovering/renovering6.jpg';
import renovering7 from '../../public/photos/renovering/renovering7.jpg';
import main_renovering from '../../public/photos/renovering/main/main_renovering.jpg';

const items = [
    {source:renovering1, id: 1},
    {source:renovering2, id: 2},
    {source:renovering3, id: 3},
    {source:renovering4, id: 4},
    {source:renovering5, id: 5},
    {source:renovering6, id: 6},
    {source:renovering7, id:7}
]


class Renovering extends Component{
    constructor(props){
        super(props);
        this.state= {
            images: items,
            currentImageNumber: 0,
            showModal: false
        };

        this.carouselHandler.bind(this);
    }

    toggleModal = async(stuff) => {
        await this.setState(({showModal: !this.state.showModal, currentImageNumber: stuff-1}));
    }

    carouselHandler = (dataFromCarousel) =>{
        this.setState({showModal: dataFromCarousel, imageNumber: this.state.currentImageNumber});
    }
    render(){

        var textStyle = { 
          position: 'absolute',
          left: '25%',
          top: '85%',
          color: 'white'
        };
        return(
            <React.Fragment>
                <LazyHero imageSrc={main_renovering}
                opacity={0} 
                parallaxOffset={200}
                minHeight="85vh" 
                isCentered={true} >
                <h1 style={textStyle}>Renovera kök, badrum eller ett helt hus.</h1>
                </LazyHero>
                <Container fluid>
                    <div>
                        <br></br>
                        <br></br>
                        <Row>
                            <Col sm="12" md={{ size: 5, offset: 4 }}>
                                <h5>
                                    <p>
                                        Renoverning är svårt att bedöma innan man vet vad som finns under det gamla. 
                                        Vi har gjort ett otalt antal renoveringar i både äldre och riktigt gamla hus och vet vad man kan stöta
                                        på och hur man bygger upp det igen. Flera av snickarna har jobbat länge och förstår gamla material och 
                                        hur det bäst hanteras.
                                    </p>
                                </h5>
                            </Col>
                        </Row>
                        <div >
                            <br></br>
                            <br></br>
                            <Row>
                                <Col md={{offset: 5}}>
                                    <p style={{fontStyle: 'italic', fontSize: '20px'}}>
                                        Ex. på renoveringar
                                    </p>
                                </Col>
                            </Row>
                            <br></br>
                            <Container>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[0].source} alt={this.state.images[0].id} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[0].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[1].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[1].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[2].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[2].id)}/></Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[3].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[3].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[4].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[4].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[5].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[5].id)}/></Col>
                                    <li></li><li></li>
                                </Row>
                                <br></br>
                                <Row>
                                <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[6].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[6].id)}/></Col>
                                    <li></li><li></li>
                                </Row>
                                <br></br>
                            </Container>
                        </div>
                    </div>
                </Container>
                <CarouselModal visible={this.state.showModal} images={this.state.images} imageId={this.state.currentImageNumber} closeCarousel={this.carouselHandler}/>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default Renovering;