import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal'
import Carousel from 'react-bootstrap/Carousel'
import {Form} from 'react-bootstrap';
import '../../components/css/modal.css';


const items =[{
    path:'http://www.indecoration.com/wp-content/uploads/2017/08/ct-elite-street-naperville-mansion-0702-biz-20150701.jpg'},
    {path: 'https://na.rdcpix.com/441543283/7d78c3da924dfcf1977b78023284c969w-c0xd-w685_h860_q80.jpg'},
    {path: 'https://rdcnewscdn.realtor.com/wp-content/uploads/2019/12/dayton-mansion.jpg'},
    {path: 'https://rdcnewscdn.realtor.com/wp-content/uploads/2019/12/hartford-tudor.jpg'},
    {path: 'https://rdcnewscdn.realtor.com/wp-content/uploads/2019/12/little-rock-mansion.jpg'}
];

class CarouselModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            showModal: this.props.visible,
            images: [],
            currentImageId: 0
        }
    }

    componentWillReceiveProps(nextProps) {
        console.log(this.props)
        if (this.props.showDialogModal != nextProps.visible) {
          this.setState({
            showModal: nextProps.visible,
            images: nextProps.images,
            currentImageId: nextProps.imageId
           });
          }
          console.log("updated state")
          console.log(this.state)
        }


    closeModal = () => {
        this.setState({showModal: false})
        this.props.closeCarousel(false);
    }
    handleSelect = () => {
        this.setState({currentImageId: this.state.currentImageId+1})
    }

    renderImages = () =>{
        return this.state.images.map(t => (
            <Carousel.Item style={{height: '100%'}}>
                <img className="d-block w-100"
                src={t.source}
                />
            </Carousel.Item>
          ))
    }
    render(){

        return(
            <React.Fragment>
                <Modal show={this.state.showModal} onHide={this.closeModal.bind(this)} size="xl">
                    <Carousel style={{height: '100%'}} defaultActiveIndex={this.state.currentImageId}>
                        {this.renderImages()}
                    </Carousel>
                </Modal>
            </React.Fragment>
        )
    }
}
export default CarouselModal;