import React, {Component} from 'react';
import Footer from '../../components/footer';
import { Container, Row, Col } from 'reactstrap';


class About extends Component{
    constructor(props){
        super(props);
        this.state= {

        };
    }
    render(){
        return(
            <React.Fragment>
                <br></br>
                    <br></br>
                    <br></br>
                <Row>
                    <Col md={{size: 6, offset: 3 }}>
                        <p style={{fontStyle: 'italic', fontSize: '20px'}}>
                        ASBygg är ett byggföretag med över 20 års erfarenhet och med hög kompetens. Vi hjälper våra kunder med allt 
                        från små renoveringar till stora totalentreprenader. Du kan även få hjälp med rådgivning inom bygg, 
                        renovering och att välja rätt material som passar just ditt projekt. Det betyder att ni får ett bra slutresultat
                         och vi garanterar att du som kund kommer att vara nöjd. Vi har flertalet återkommande nöjda kunder som i sin tur rekommenderar oss vidare till
                          sina vänner och bekanta. 
                        </p>
                        <p style={{fontStyle: 'italic', fontSize: '20px'}}>
                        Hör med oss… Det brukar löna sig!
                        </p>
                    </Col>
                </Row>
                <div style={{
                    paddingBottom:'0px', 
                    position:'absolute', 
                    left:'0',
                    bottom:'0',
                    right:'0'
                    }}>
                    <Footer/>
                </div>
            </React.Fragment>
        )
    }
}

export default About;