import {BACKEND_URL, PROJECTS_ALL_NAMES, PROJECTS_FOR_CUSTOMER} from '../helpers/urls';

export const ProjectService = {
    getAllNames,
    getCustomerProjects
}

async function getAllNames(){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json; charset=UTF-8'}
    };

    var response = await fetch(BACKEND_URL + PROJECTS_ALL_NAMES);
    var data = await response.json();

    console.log(data);
    return data;
}

async function getCustomerProjects(customerId){
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json; charset=UTF-8'}
    };

    var response = await fetch(BACKEND_URL + PROJECTS_FOR_CUSTOMER + customerId);
    var data = await response.json();

    console.log(data);
    return data;
}

export default ProjectService;