import React, {Component} from 'react';
import {Container} from 'reactstrap';
import {Table, Button} from 'react-bootstrap';
import TimesheetModal from '../../components/modals/timesheetModal';
import TimesheetService from '../../services/timesheetService';


const myTimes= [
  {
    id: 1,
    date: '2020-05-11',
    begin: '08:00',
    end: '19:00',
    duration: '11:00 h',
    customer: 'tomas',
    project: 'first time',
    activity: 'first actvitiy',
    description: 'some description'
},
{
  id: 2,
  date: '2020-05-12',
  begin: '08:00',
  end: '19:00',
  duration: '11:00 h',
  customer: 'tomas',
  project: 'second time',
  activity: 'second actvitiy',
  description: 'some description'
},
{
  id: 3,
  date: '2020-05-13',
  begin: '08:00',
  end: '19:00',
  duration: '11:00 h',
  customer: 'tomas',
  project: 'some project',
  activity: 'some actvitiy',
  description: 'some description'
},
{
  id: 4,
  date: '2020-05-15',
  begin: '08:00',
  end: '19:00',
  duration: '11:00 h',
  customer: 'tomas',
  project: 'any project',
  activity: 'any actvitiy',
  description: 'any description'
},
{
  id: 5,
  date: '2020-05-16',
  begin: '08:00',
  end: '19:00',
  duration: '11:00 h',
  customer: 'tomas',
  project: 'any project',
  activity: 'any actvitiy',
  description: 'any description'
},
{
  id: 6,
  date: '2020-05-17',
  begin: '08:00',
  end: '19:00',
  duration: '11:00 h',
  customer: 'tomas',
  project: 'any project',
  activity: 'any actvitiy',
  description: 'any description'
},
{
  id: 7,
  date: '2020-05-18',
  begin: '10:00',
  end: '19:00',
  duration: '11:00 h',
  customer: 'tomas',
  project: 'any project',
  activity: 'any actvitiy',
  description: 'any description'
},
]

export default class Timesheet extends Component {
    constructor(props){
        super(props);
        this.state={
          id: '',
          selectedIds: [],
          selectedRows: [],
          showModal: false,
          allChecked: false,
          formValues: myTimes,
          timesheets : []
      };
      this.handleRowClick = this.handleRowClick.bind(this)
    }

    componentDidMount = async () => {
      var user = JSON.parse(localStorage.getItem('user'));
      var allTimes = await TimesheetService.getUserTimesheets(user.id)
      this.setState({timesheets : allTimes})
      console.log(this.state)
    }

    handleRowClick = (timesheetId) => {
      this.setState({
        showModal: true,
        id: timesheetId
      })
      
    }


    renderTableRows = () => {
        return this.state.timesheets.map(t => (
        <tr style={{ textAlign: 'center' }} key={t.id}>
          <td><input key={t.id} type="checkbox" onChange={() => this.handleCheck(t)} checked={this.state.allChecked && this.state.selectedIds.indexOf(t.id) !== -1}/></td>
          <td onClick={() => this.handleRowClick(t.id)}>{t.start_time.substring(0, 10)}</td>
          <td onClick={() => this.handleRowClick(t.id)}>{t.start_time.substring(11)}</td>
          <td onClick={() => this.handleRowClick(t.id)}>{t.end_time != null ? t.end_time : " --- "}</td>
          <td onClick={() => this.handleRowClick(t.id)}>{t.duration != 0 ? t.formattedDuration + "h" : "---"}</td>
          <td onClick={() => this.handleRowClick(t.id)}>{t.customer}</td>
          <td onClick={() => this.handleRowClick(t.id)}>{t.project}</td>
          <td onClick={() => this.handleRowClick(t.id)}>{t.activity}</td>
          <td onClick={() => this.handleRowClick(t.id)}>{t.description}</td>
        </tr>
      ))
    };

    handleCheckAll = async() => {
      var isAllChecked = this.state.allChecked;
      this.setState({allChecked: !isAllChecked})
      if(isAllChecked){
        console.log("should empty ")
        var emptyArray = [];
        await this.setState({
          selectedIds: emptyArray,
          selectedRows: emptyArray
        })
      }
      else{
        console.log("should populate")
        await this.setState({
          selectedIds: this.state.formValues.map(x=>x.id),
          selectedRows: this.state.formValues.map(x=> x)
        })
        
        console.log(this.state)
        // loop over all values. Maybe from state?
        //store all ids in selectedIds and values in selectedRows
      }

      console.log(this.state)
      console.log("should handle all checked")
    }

    handleCheck = async (t) => {

      //check if selectedrows is populated
      if(await this.state.selectedRows.length > 0){

        var selectedRows = await this.state.selectedRows;
        //checks if id from all selected timesheets matches the provided values id.
        // if true- removes that object from array by index value, else insert object to array.
        await selectedRows.map(x=> x.id == t.id ? selectedRows.splice(selectedRows.indexOf(x), 1) : x)
        await this.setState({selectedRows: selectedRows})
        console.log(this.state)
        
      }
      //check if selectedids is populated
      if(await this.state.selectedIds !== null){
        var indexOfT = await this.state.selectedIds.indexOf(t.id);
        // check if there is matching id in selectedIds. if so, remove id.
        if(indexOfT !== -1){
          var oldValues = this.state.selectedIds;
          var testVals = []
          var newValues = oldValues.splice(indexOfT, 1) && oldValues
          await this.setState({selectedIds: newValues})
        }
        // if checbox oncheck row's id value doesnt match any of selectedids values, add to rows and ids
        else{
          await this.setState({selectedRows: [...this.state.selectedRows, t]})
          await this.setState({selectedIds:[...this.state.selectedIds, t.id]})

        }
      this.state.selectedIds.indexOf(t.id) === -1 ? console.log("uncheck") : console.log("checked")}
        console.log("checked rows:")
        console.log(t);
        console.log("selected ids" + JSON.stringify(this.state))
    }

    //test function to add values to table
    testAddValuesToTable = async() => {
      // var stuff = await this.state.formValues;
      // stuff.push({
      //     id: 8,
      //     date: '2020-05-20',
      //     begin: '10:00',
      //     end: '19:00',
      //     duration: '11:00 h',
      //     customer: 'tomas',
      //     project: 'any project',
      //     activity: 'any actvitiy',
      //     description: 'any description'
        
      // })
      // console.log(stuff)
      // await this.setState({formValues: stuff})
      var user = JSON.parse(localStorage.getItem('user'));
      var allTimes = await TimesheetService.getUserTimesheets(user.id)
      this.setState({timesheets : allTimes})
      console.log(this.state)

    }

    render(){
        return(
            <React.Fragment>
              <Container/>
              <Container style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh', top: '50%'}}>
            <main className="content">
                <Container>
                <Button onClick={() => this.testAddValuesToTable()}>Testbtn</Button>

              <Table bordered>
                <thead>
                  <tr>
                    <th><input type="checkbox" checked={this.state.allChecked} onChange={() => this.handleCheckAll()}/> </th>
                    <th>Date</th>
                    <th>Start time</th>
                    <th>End time</th>
                    <th>Duration</th>
                    <th>Customer</th>
                    <th>Project</th>
                    <th>Activity</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                {this.renderTableRows()}
                </tbody>
              </Table>
              </Container>
            </main>
            <TimesheetModal visible={this.state.showModal} timesheetId={this.state.id}/>
            </Container>
        </React.Fragment>
        )
    }
}