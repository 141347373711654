import React, {Component} from 'react';
import {Container} from 'reactstrap';

class Footer extends Component{
    constructor(props){
        super(props);
        this.state= {

        };
    }

    render(){
        return(
            <React.Fragment>
                <div id="foot"
                    style={{backgroundColor: '#212529'}}
                >
                    <Container>
                        <h5 style={{whiteSpace:'pre-wrap', color:'#bec6cc'}}>
                            <br></br>
                            AS Bygg El & Alltjänst AB
                            <br></br>
                            J A Bobäcks gata 4
                            <br></br>
                            544 31 HJO
                            <br></br>
                            073-976 29 14
                            <br></br>
                            asbyggalltjanst@gmail.com
                            <br></br>
                            <br></br>
                        </h5>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Footer;