import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import Footer from '../../components/footer';
import Image from 'react-bootstrap/Image';
import CarouselModal from '../../components/modals/carouselModal';
import LazyHero from 'react-lazy-hero';
import 'bootstrap/dist/css/bootstrap.min.css';
import tillbyg1 from '../../public/photos/tillbyggnad/tillbygg1.jpg';
import tillbyg2 from '../../public/photos/tillbyggnad/tillbygg2.jpg';
import tillbyg3 from '../../public/photos/tillbyggnad/tillbygg3.JPG';
import tillbyg4 from '../../public/photos/tillbyggnad/tillbygg4.jpg';
import tillbyg5 from '../../public/photos/tillbyggnad/tillbygg5.jpg';
import tillbyg6 from '../../public/photos/tillbyggnad/tillbygg6.jpg';
import tillbyg7 from '../../public/photos/tillbyggnad/tillbygg7.JPG';
import tillbyg8 from '../../public/photos/tillbyggnad/tillbygg8.jpg';
import tillbyg9 from '../../public/photos/tillbyggnad/tillbygg9.jpg';
import tillbyg10 from '../../public/photos/tillbyggnad/tillbygg10.jpg';
import tillbyg11 from '../../public/photos/tillbyggnad/tillbygg11.jpg';
import tillbyg12 from '../../public/photos/tillbyggnad/tillbygg12.jpg';
import tillbyg13 from '../../public/photos/tillbyggnad/tillbygg13.jpg';
import tillbyg14 from '../../public/photos/tillbyggnad/tillbygg14.jpg';
import main_tillbygg from '../../public/photos/tillbyggnad/main/main_tillbygg.jpg';



const items = [
    {source:tillbyg1, id:1},
    {source:tillbyg2, id:2},
    {source:tillbyg3, id:3},
    {source:tillbyg4, id:4},
    {source:tillbyg5, id: 5},    
    {source:tillbyg6, id: 6},
    {source:tillbyg7, id: 7},
    {source:tillbyg8, id: 8},
    {source:tillbyg9, id: 9},
    {source:tillbyg10, id:10},
    {source:tillbyg11, id:11},
    {source:tillbyg12, id:12},
    {source:tillbyg13, id:13},
    {source:tillbyg14, id:14}
]


class Tillbyggnad extends Component{
    constructor(props){
        super(props);
        this.state= {
            images: items,
            currentImageNumber: 0,
            showModal: false
        };

        this.carouselHandler.bind(this);
    }

    toggleModal = async(stuff) => {
        await this.setState(({showModal: !this.state.showModal, currentImageNumber: stuff-1}));
    }

    changeImgNumberAndOpenModal = (index) => {
        this.setState(({currentImageNumber: index}));
        this.toggleModal();

    }
    carouselHandler = (dataFromCarousel) =>{
        this.setState({showModal: dataFromCarousel});
    }
    render(){

        var textStyle = { 
          position: 'absolute',
          left: '25%',
          top: '85%',
          color: 'white'
        };
        return(
            <React.Fragment>
                            <LazyHero
                             imageSrc={main_tillbygg}
                             minHeight="85vh" 
                             opacity={0}
                             parallaxOffset={200}
                             isCentered={true} 
                             >
                            <h1 style={textStyle}>Bygga till befintligt eller ett attefall på tomten </h1>
                        </LazyHero>
                <Container fluid>
                    <div>
                        <br></br>
                        <br></br>
                        <Row>
                            <Col sm="12" md={{ size: 5, offset: 4 }}>
                                <h5>
                                    <p>
                                        Vår långa erfarenhet av olika tillbyggen och planlösningar skapar förutstäggningar för ett lyckat projekt.
                                         Av alla de projekt vi varit involverade i plockar vi med oss erfarenheter som vi tar vidare in i nästa. 
                                         Vi är gärna med i processen för det är allt bra att fundera en extra gång. 
                                         I synnerhet när man bygger till så det passar bra med befintliga byggnader.
                                    </p>
                                </h5>                                
                            </Col>
                        </Row>
                        <div >
                            <br></br>
                            <br></br>
                            <Row>
                                <Col md={{offset: 5}}>
                                    <p style={{fontStyle: 'italic', fontSize: '20px'}}>
                                        Ex. på ut och tillbyggnader
                                    </p>
                                </Col>
                            </Row>
                            <br></br>
                            <Container>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[0].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[0].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[1].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[1].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[2].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[2].id)}/></Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[3].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[3].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[4].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[4].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[5].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[5].id)}/></Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[6].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[6].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[7].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[7].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[8].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[8].id)}/></Col>
                                    <li></li><li></li>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[9].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[9].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[10].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[10].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[11].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[11].id)}/></Col>
                                    <li></li><li></li>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[12].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[12].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[13].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[13].id)}/></Col>
                                    <li></li><li></li>
                                </Row>
                                <br></br>
                            </Container>
                        </div>
                    </div>
                </Container>
                <CarouselModal visible={this.state.showModal} images={this.state.images} imageId={this.state.currentImageNumber} closeCarousel={this.carouselHandler}/>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default Tillbyggnad;