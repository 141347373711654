import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Switch } from 'react-router-dom';
import Home from './pages/public/home';
import About from './pages/public/about';
import Contact from './pages/public/contact';
import Nybyggnation from './pages/public/nybyggnation';
import Tillbyggnad from './pages/public/tillbyggnad';
import Renovering from './pages/public/renovering';
import Fallning from './pages/public/fallning';
import Isolering from './pages/public/isolering';
import Badrum from './pages/public/badrum';
//import Dashboard from './pages/dashboard'
import Sidebar from './components/sidebar/sidebar';
import OuterNavigationBar from './components/navbar';
import PrivateRoute from './components/routes/privateRoute';
import PublicRoute from './components/routes/publicRoute';
import MainNavigationBar from './components/MainNavigationBar';
import Timesheet from './pages/private/timesheet';


class App extends Component {
  constructor(props){
    super(props);
    this.state={
      user: localStorage.getItem('user'),
      isLoggedIn: false
    }
  }
componentDidMount = async () => {
  this.checkLogin();
}
checkLogin = async () => {
  if(localStorage.getItem('user')){
    await this.setState({isLoggedIn: true})
  }
}

  render() {
    return (
      <BrowserRouter>
      <React.Fragment>
        {this.state.isLoggedIn && <OuterNavigationBar/>}
        {this.state.isLoggedIn && <Sidebar/>}
        {!this.state.isLoggedIn && <MainNavigationBar/>}
        <Switch>
          <PrivateRoute restricted={false} component={props => <Timesheet/>} path="/timesheet" />
          <PublicRoute restricted={false} component={props => <Home/> } path="/" exact />
          <PublicRoute restricted={false} component={props => <Home/> } path="/home" />
          <PublicRoute restricted={false} component={props => <Nybyggnation/> } path="/nybyggnation" />
          <PublicRoute restricted={false} component={props => <Tillbyggnad/> } path="/tillbyggnad" />
          <PublicRoute restricted={false} component={props => <Renovering/> } path="/renovering" />
          <PublicRoute restricted={false} component={props => <Fallning/> } path="/fallning" />
          <PublicRoute restricted={false} component={props => <Isolering/> } path="/isolering" />
          <PublicRoute restricted={false} component={props => <Badrum/> } path="/badrum" />
          <PublicRoute restricted={false} component={props => <About/> } path="/about" />
          <PublicRoute restricted={false} component={props => <Contact/> } path="/contact" />
        </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}



export default App;