import React, {Component} from 'react';
import Carousel from 'react-bootstrap/Carousel'
import tillbyggd8 from '../public/photos/tillbygg8.jpg';
import main2 from '../public/photos/main2.JPG';
import main_nybygg from '../public/photos/main_nybygg.jpg';
import main_falning from '../public/photos/main_falning.jpg';
import main_tillbygg from '../public/photos/tillbyggnad/main/main_tillbygg.jpg';
import badrum4 from '../public/photos/badrum/badrum4.jpg';


const items =[{
        path:tillbyggd8},
        {path: main_nybygg},
        {path: main_falning},
        {path: main_tillbygg},
        {path:badrum4},
        {path: main2}
];

class Slideshow  extends Component{
    constructor(props){
        super(props);
        this.state={
            images: items
        }
    }


    renderImages = () =>{
        return this.state.images.map(t => (
            <Carousel.Item style={{height: 700}}>
                <img className="d-block w-100"
                src={t.path}
                />
            </Carousel.Item>
          ))
    }

    render(){
        return(
            <React.Fragment>
                <Carousel style={{height: 700}}>
                    {this.renderImages()}
                </Carousel>
            </React.Fragment>
        )
    }
}
export default Slideshow;