import React, {Component} from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
//import './sidebar.css';
import './sidebar.css';
import styled from 'styled-components';
import 'font-awesome/css/font-awesome.min.css';
import ClickOutside from 'react-click-outside';
import userService from '../../services/userService';


const Separator = styled.div`
    clear: both;
    position: relative;
    margin: .8rem 0;
    background-color: #696969;
    height: 1px;
`;


class Sidebar extends Component{
    constructor(props){
        super(props);
        this.state = {
            expanded: false
        }
    }

    logout = () => {
        userService.logout();
    }
    render(){
        return(
            <React.Fragment>
                <ClickOutside onClickOutside={() => {
                    this.setState({expanded: false});
                    console.log("outside")
                }}>
                <SideNav
                expanded={this.state.expanded}
                onToggle={(expanded) => {
                    this.setState({expanded})
                }}
                onSelect={(selected) => {
                    console.log(selected)

                    // Add your code here
                }}>
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="home">
                    <NavItem eventKey="home">
                        <NavIcon>
                            <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Home
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="charts">
                        <NavIcon>
                            <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Charts
                        </NavText>
                        <NavItem eventKey="charts/linechart">
                            <NavText>
                                Line Chart
                            </NavText>
                        </NavItem>
                        <NavItem eventKey="charts/barchart">
                            <NavText>
                                Bar Chart
                            </NavText>
                        </NavItem>
                    </NavItem>
                <Separator/>
                <NavItem eventKey="login" onClick={() => this.logout()}>
                    <NavIcon>
                        <i className="fa fa-fw fa-power-off" style={{fontSize: '1.75em'}}/>
                    </NavIcon>
                    <NavText>
                        Logout
                    </NavText>
                </NavItem>
            </SideNav.Nav>
            </SideNav>
            </ClickOutside>
            </React.Fragment>
        )
    }
}
export default Sidebar;