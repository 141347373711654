import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import Footer from '../../components/footer';
import Image from 'react-bootstrap/Image';
import CarouselModal from '../../components/modals/carouselModal';
import LazyHero from 'react-lazy-hero';
import 'bootstrap/dist/css/bootstrap.min.css';
import rsz_img_0104 from '../../public/photos/nybyggnation/rsz_img_0104.jpg';
import rsz_img_0215 from '../../public/photos/nybyggnation/rsz_img_0215.jpg';
import rsz_img_0204 from '../../public/photos/nybyggnation/rsz_img_0204.jpg';
import rsz_img_0206 from '../../public/photos/nybyggnation/rsz_img_0206.jpg';
import rsz_img_0207 from '../../public/photos/nybyggnation/rsz_img_0207.jpg';
import rsz_img_0208 from '../../public/photos/nybyggnation/rsz_img_0208.jpg';
import rsz_img_0210 from '../../public/photos/nybyggnation/rsz_img_0210.jpg';
import rsz_img_0211 from '../../public/photos/nybyggnation/rsz_img_0211.jpg';
import rsz_img_0213 from '../../public/photos/nybyggnation/rsz_img_0213.jpg';
import main_nybygg from '../../public/photos/nybyggnation/main/main_nybygg.jpg';



const items = [
    {source:rsz_img_0104, id:1},
    {source:rsz_img_0215, id:2},
    {source:rsz_img_0204, id:3},
    {source:rsz_img_0206, id:4},
    {source:rsz_img_0207, id:5},
    {source:rsz_img_0208, id:6},
    {source:rsz_img_0210, id:7},
    {source:rsz_img_0211, id:8},
    {source:rsz_img_0213, id:9},
]


class Nybyggnation extends Component{
    constructor(props){
        super(props);
        this.state= {
            images: items,
            currentImageNumber: 0,
            showModal: false,
            showFooter: false
        };

        this.carouselHandler.bind(this);
    }

    toggleModal = async(stuff) => {
        await this.setState(({showModal: !this.state.showModal, currentImageNumber: stuff-1}));
    }

    changeImgNumberAndOpenModal = (index) => {
        this.setState(({currentImageNumber: index}));
        this.toggleModal();

    }
    carouselHandler = (dataFromCarousel) =>{
        this.setState({showModal: dataFromCarousel});
    }

    render(){

        var textStyle = { 
          position: 'absolute',
          left: '25%',
          top: '85%',
          color: 'white'
        };
        return(
            <React.Fragment>
                <LazyHero imageSrc={main_nybygg} 
                opacity={0} 
                parallaxOffset={200} 
                minHeight="85vh" 
                isCentered >
                <h1 style={textStyle}>Få det huset just du vill ha. Från början till slut</h1>
                </LazyHero>
                <Container fluid>
                    <div>
                        <br></br>
                        <br></br>
                        <Row>
                            <Col sm="12" md={{ size: 5, offset: 4 }}>
                                <h5>
                                    <p>
                                        Om du som kund ska bygga ett helt nytt hus och har en idé så har vi en bra start. 
                                        Vi har erfarenhet av många nyproduktioner och har både erfarenhet och kunskap vad 
                                        man bör tänka på och förslag på hur man kan ändra planlösningar för att det ska bli ett bättre och mer funktionellt hus.
                                    </p>
                                </h5>
                            </Col>
                        </Row>
                        <div >
                            <br></br>
                            <br></br>
                            <Row>
                                <Col md={{offset: 5}}>
                                    <p style={{fontStyle: 'italic', fontSize: '20px'}}>
                                        Ex. på nybyggnation
                                    </p>
                                </Col>
                            </Row>
                            <br></br>
                            <Container>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[0].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[0].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[1].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[1].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[2].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[2].id)}/></Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEventss": "all"}} src={this.state.images[3].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[3].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[4].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[4].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[5].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[5].id)}/></Col>
                                </Row>
                                <br></br>
                                <Row>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[6].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[6].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[7].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[7].id)}/></Col>
                                    <li></li><li></li>
                                    <Col md={{ size: 2, offset: 1 }}><Image style={{"pointerEvents": "all"}} src={this.state.images[8].source} height="200" width="300" onClick={this.toggleModal.bind(this, this.state.images[8].id)}/></Col>
                                </Row>
                                <br></br>
                            </Container>
                        </div>
                    </div>
                </Container>
                <CarouselModal visible={this.state.showModal} images={this.state.images} imageId={this.state.currentImageNumber} closeCarousel={this.carouselHandler}/>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default Nybyggnation;