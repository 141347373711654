import React, { Component } from 'react';
import {Form, Row, Button} from 'react-bootstrap';
import TimesheetService from '../../services/timesheetService';
import CustomerService from '../../services/customerService';
import ProjectService from '../../services/projectService';
import Select from 'react-select'


const moment = require('moment')

const dateNow= Date();

class TimesheetForm extends Component{
    constructor(props){
        super(props);
        this.state= {
            id: props.timesheetId,
                startTime: moment(dateNow).format('YYYY-MM-DD hh:mm'),
                endTime: "yyyy-DD-mm HH:mm",
                duration: "00:00",
                customer: "",
                project: "",
                activity: "",
                description: "",
                allCustomers: [],
                fetchingCustomers: true
        }
    };

    componentDidMount = async() =>{
        // check for id. if its not undefined fetch data by state.id; store to formData in render method
        if(this.state.id !== undefined) {
            var timesheet = await TimesheetService.getTimesheetById(this.state.id)
            this.setState({
                startTime: timesheet.start_time,
                endTime: timesheet.end_time,
                duration: timesheet.formattedDuration,
                customer: timesheet.customer,
                project: timesheet.project,
                activity: timesheet.activity,
                description: timesheet.description,
                allCustomers: [],
                selectedCustomer: "",
                projects: [],
                selectedProject: ""
            })
        }
        await this.getCustomers();
        var selected = await this.getSelectedCustomer();
        this.setState({selectedCustomer: selected});
        //if(this.state.id !== undefined){console.log("fetch data here")}
    }

    getSelectedCustomer(){
        var selected;
        this.state.allCustomers.map(item=> {
            if(item.label === this.state.customer){
                selected = item
            }
        })
        return selected;
    }

    fetchData = async() => {
        var id = await this.props.timesheetId

        console.log("mount")
        console.log(id)
    }
    sendData = () => {
        console.log("should choose between create and edit(add states: create, edit)")
        var formData = {
            id: this.state.id,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            duration: this.state.duration,
            customer: this.state.customer,
            project: this.state.project,
            activity: this.state.activity,
            description: this.state.description
        };
        this.props.formCallback(formData)
    }

    getCustomers = async() =>{
        var customer = await CustomerService.getCustomerNames();
        this.setState({allCustomers: customer})
    }

    getProjectsByCustomerId = async() => {

        var customerProjects = await ProjectService.getCustomerProjects(this.state.selectedCustomer.value);
        this.setState({projects: customerProjects});
    }

    renderDropdown =() => {
        return <Select options={this.state.allCustomers} value={this.state.selectedCustomer} onChange={this.handleCustomerChange}/>
    }
    handleCustomerChange = selectedOption => {
        this.setState(
          { selectedCustomer: selectedOption },
          () => console.log(`Option selected:`, this.state.selectedCustomer)
        );
        //var allProjects = this.getProjectsByCustomerId(selectedOption.value);
        //this.setState({projects: allProjects});
        this.getProjectsByCustomerId();
        console.log(this.state);
      };

    render(){
            const formData = {
            id: this.state.id,
            startTime: moment(dateNow).format('YYYY-MM-DD hh:mm'),
            endTime: "yyyy-DD-mm HH:mm",
            duration: "00:00",
            customer: "",
            project: "",
            activity: "",
            description: "",
            allCustomers: this.state.allCustomers       
    }
    if(this.state.id > 0) {
        // set formdata from state to have as placeholders
        formData.startTime = "fetch data"
    }
        return(
            <React.Fragment>
            <Form>
                <Form.Group as={Row} controlId="timeDuration">
                    <Form.Label>
                        From*
                    </Form.Label>
                    <Form.Control type="startDate" value={this.state.startTime} onChange={e=> this.setState.startTime = e}/>
                    <Form.Label>
                        To
                    </Form.Label>
                    <Form.Control type="endDate" value={this.state.endTime} onChange={e=> this.setState.endTime=e}/>
                    <Form.Label>
                        Duration
                    </Form.Label>
                    <Form.Control type="duration" value={this.state.duration} onChange={e=> this.setState.duration=e}/>
                </Form.Group>
                <Form.Group controlId="customer">
                    <Form.Label column sm="2">
                        Customer
                    </Form.Label>
                    {this.renderDropdown()}
                </Form.Group>
                {//should be populated dropdown with search
                 }
                 <Form.Group controlId="project">
                     <Form.Label column sm="2">
                         Project*
                     </Form.Label>
                     <Form.Control type="project" value={this.state.project} onChange={e=> this.setState.project=e}/>
                 </Form.Group>
                 {//should be populated dropdown with search
                 }
                 <Form.Group controlId="activity">
                     <Form.Label column sm="2">
                         Activity*
                     </Form.Label>
                     <Form.Control type="activity" value={this.state.activity} onChange={e=> this.setState.activity=e}/>
                 </Form.Group>
                 {//should be populated dropdown with search
                 }
                 <Form.Group controlId="description">
                     <Form.Label column sm="2">
                         Description
                     </Form.Label>
                     <Form.Control type="description" value={this.state.description} onChange={e=> this.setState.description=e}/>
                 </Form.Group>
            </Form>
            <Button hidden={this.state.id !== undefined ? true : false} onClick={this.sendData}>Create</Button>
            <Button hidden={this.state.id === undefined ? true : false} onClick={this.sendData}>Save</Button>
            <Button className="" onClick={this.sendData}>Cancel</Button>
            </React.Fragment>
        )
    }
}
export default TimesheetForm;
