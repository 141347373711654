import React, {Component} from 'react';
import {Nav, Navbar, Button, NavDropdown, DropdownItem} from 'react-bootstrap'
import {Link, NavLink} from 'react-router-dom'
import LoginModal from '../components/modals/loginModal';


class MainNavigationBar extends Component{
    constructor(props){
        super(props);
        this.state= {
            showLoginModal: false
        };
        this.loginModalHandler.bind(this);
    }

    showLoginModal = async() => {
        await this.setState({showLoginModal: true})
    }

    scrollToView = () => {
        var item = document.getElementById("foot");
        if(item){
            return item.scrollIntoView();
        }
    }

    loginModalHandler = (dataFromModal) => {
        this.setState({showLoginModal: dataFromModal})
    }

    render(){
        return(
            <React.Fragment>
                <Navbar bg="dark" variant="dark" expand="xl" >
                    <Navbar.Brand as={Link} to="/">ASBygg</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link as={NavLink} to="/home">Hem</Nav.Link>
                            <Nav.Link as={NavLink} to="/nybyggnation">Nybyggnation</Nav.Link>
                            <Nav.Link as={NavLink} to="/tillbyggnad">Tillbyggnad</Nav.Link>
                            <Nav.Link as={NavLink} to="/isolering">Isolering</Nav.Link>
                            <Nav.Link as={NavLink} to="/renovering">Renovering</Nav.Link>
                            <Nav.Link as={NavLink} to="/fallning">Trädfällning / Stensättning</Nav.Link>
                            <Nav.Link as={NavLink} to="/badrum">Badrum</Nav.Link>
                            <Nav.Link as={NavLink} to="/about">Om oss</Nav.Link>
                            <Nav.Link onClick={this.scrollToView}>Kontakta oss</Nav.Link>
                            <Button variant="secondary" style={{backgroundColor:'transparent', borderColor:'transparent'}} onClick={this.showLoginModal.bind(this)}> Logga in </Button>
                            <LoginModal visible={this.state.showLoginModal} closeModal={this.loginModalHandler}/>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </React.Fragment>
        )
    }
}
export default MainNavigationBar;