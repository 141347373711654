import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import { ModalHeader, ModalBody, ModalFooter, ButtonGroup, Button } from 'reactstrap';
import TimesheetForm from '../forms/timessheetForm';

class TimesheetModal extends Component{
    constructor(props){
        super(props);
        this.render.bind(this)
        this.state = {
            timesheetId: this.props.timesheetId,
            showModal: this.props.visible
        };  
        this.formHandler = this.formHandler.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.showDialogModal != nextProps.visible) {
          this.setState({
            showModal: nextProps.visible
           });
          }
        }
        formHandler = async(formData) => {
            await console.log(this.props)
            await console.log(formData)
            this.setState({showModal: false})
            this.sendData(formData)
            this.closeModal();
        }
        closeModal = () => {
            this.setState({showModal: false})
        }

        sendData = (data) => {
            data.id > 0 ? console.log("id more than 0. Function to update") : console.log("no id. Function to create new timesheet")
            console.log("function to send data. TODO")
        }

        giveStateId = () => {
            var id = this.props.timesheetId
            return id
        }

        // sends timesheet id to form if clicked on row
        renderTimesheet = () =>{
            return <TimesheetForm formCallback={this.formHandler} timesheetId={this.giveStateId()}/>
        }

    render(){
        return(
            <React.Fragment>
                <Modal size="lg" show={this.state.showModal} onHide={this.closeModal.bind(this)}>
                    <ModalHeader>
                        Create
                    </ModalHeader>
                    <ModalBody>
                        {this.renderTimesheet()}
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}
export default TimesheetModal