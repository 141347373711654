import React, { Component } from 'react';
import { Nav, Navbar, Form, FormControl, NavIcon, ButtonGroup, DropdownButton, DropdownItem, Button } from 'react-bootstrap';
import styled from 'styled-components';
import 'font-awesome/css/font-awesome.min.css';
import TimesheetModal from './modals/timesheetModal';
import TimesheetService from '../services/timesheetService';


const Styles = styled.div`
  .navbar { background-color: #222; }
  a, .navbar-nav, .navbar-light .nav-link {
    color: #9FFFCB;
    &:hover { color: white; }
  }
  .navbar-brand {
    font-size: 1.4em;
    color: #9FFFCB;
    &:hover { color: white; }
  }
  .form-center {
    position: absolute !important;
    left: 25%;
    right: 25%;
  }
  .btn-primary {
      background-color: #222;
      border-color: #222
  }
  .dropdown-menu {
      left: -73px
  }
`;
class OuterNavigationBar extends Component{
    constructor(props){
        super(props);
        this.state={
            timerStarted: false,
            showModal: false
        };
    }
    componentDidMount = async() =>{
        var user = JSON.parse(localStorage.getItem('user'));
        var isStarted= await TimesheetService.isTimerStarted(user.id);
        this.setState({timerStarted: isStarted});
    }

    showStartButton = () => {
        if(!this.state.timerStarted){
            return <i className="fa fa-play-circle fa-2x"></i>
        }
        else{
            return <i className="fa fa-stop-circle fa-2x"></i>
        }
    }

    showModal = async() => {
        if(this.state.timerStarted){
            var user = JSON.parse(localStorage.getItem('user'));
            var lastTimesheet = await TimesheetService.getLastTimesheet(user.id);
            var timesheetInfo = {"id" : 5, "end_time": new Date().toLocaleString()};
            var response = TimesheetService.stopTimesheet(lastTimesheet.id, new Date().toLocaleString());
            console.log(response)
            console.log("should stop timesheet..")
            console.log("should reload page i guess?")
        }
        else{
          await this.setState({showModal: true})
        console.log("should open modal")
        console.log(this.state)  
        }
        
    }

    render(){
        return(
            <Styles>
    <Navbar expand="lg">
      <Navbar.Brand></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
            <Button onClick={this.showModal.bind(this)}>
                {this.showStartButton()}
            </Button>
            <TimesheetModal visible={this.state.showModal}/>
            <DropdownButton title={<i className="fa fa-user-circle fa-2x"/>}>
                <DropdownItem eventKey="1">
                    item123232323
                </DropdownItem>
            <DropdownItem eventKey="2">
                item2
            </DropdownItem>
            </DropdownButton>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Styles>
        )
    }
}

export default OuterNavigationBar;